<template>
    <div>
        <h3>Positions</h3>
        <p>👉🏼 We have <b>Positions</b> to project</p>
        <br>
        <h5>Utilities for controlling how an element is positioned in the DOM</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Properties</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-static</td>
                    <td>
                        <mark>position: static;</mark>
                    </td>
                </tr>
                <tr>
                    <td>ba-fixed</td>
                    <td>
                        <mark>position: fixed;</mark>
                    </td>
                </tr>
                <tr>
                    <td>ba-absolute</td>
                    <td>
                        <mark>position: absolute;</mark>
                    </td>
                </tr>
                <tr>
                    <td>ba-relative</td>
                    <td>
                        <mark>position: relative;</mark>
                    </td>
                </tr>
                <tr>
                    <td>ba-sticky</td>
                    <td>
                        <mark>position: sticky;</mark>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>