<template>
    <div>
        <h3>Modals</h3>
        <p>👉🏼 We have multiple <b>modals</b>, in which you can include any content you want</p>
        <br>

        <div>
          <p>The modal structure is very simple:</p>
          <ul>
            <li>
              <code>ba-modal</code>: the main container
              <ul>
                <li><code>ba-modal-background</code>: a transparent overlay that can act as a click target to close the modal</li>
                <li>
                  <code>ba-modal-content</code>: a horizontally and vertically centered container,
                  in which you can include <em>any</em> content
                </li>
                <li>
                  <code>ba-modal-card</code>: a flex container with three class sections to organize the content: 
                  <strong>ba-modal-card-head</strong>, <strong>ba-modal-card-body</strong> and <strong>ba-modal-card-foot</strong> (the latter can be used optionally)
                </li>
                <li><code>ba-modal-close</code>: a simple cross located in the top right corner</li>
              </ul>
            </li>
          </ul>  
          
          <div>
            <p class="font-normal">
              To <strong>activate</strong> the modal, just add the <code>is-active</code> modifier on the
              <code>.modal</code> container. You may also want to add <code>is-clipped</code> modifier to a containing element
              (usually <code>html</code>) to stop scroll overflow.
            </p>

            <div class="ba-alert ba-info">
              <span class="font-normal">This example does not include any JavaScript. However, you can use <strong>Vanilla JS</strong> to handle the mouse click event and add/remove the <code>is-active</code> class, or do this with <strong>React/Vue</strong>, or any other js framework.</span>
            </div>
          </div>
        </div>
        <br>

        <!-- MODAL EXAMPLE -->
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-modal</b> <span class="font-normal">+</span> <b>ba-modal-content</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-modal'>
  <div class='ba-modal-background'></div>
  <div class='ba-modal-content'>
    <!-- Any other elements you want -->
    <button class='ba-modal-close' aria-label='close'></button>
  </div>  
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
              <p>Result: </p>
              <button class="ba-btn ba-md ba-blue" @click="openModal('isActiveModal')">
                Launch modal
              </button>
              <div :class="['ba-modal', { 'is-active': isActiveModal }]">
                <div class="ba-modal-background" @click="closeModal('isActiveModal')"></div>
                <div class="ba-modal-content">
                  <article class="ba-flex ba-bg-white2 ba-relative ba-w-100">
                    <div class="media-left ba-pd-t-1">
                      <figure class="image is-64x64">
                        <img src="https://bulma.io/assets/images/placeholders/128x128.png" alt="Image">
                      </figure>
                    </div>
                    <div >
                      <div>
                        <p>
                          <strong>John Smith</strong> <small>@johnsmith</small> <small>31m</small>
                          <br>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean efficitur sit amet massa fringilla
                          egestas. Nullam condimentum luctus turpis.
                        </p>
                      </div>
                      <nav>
                        <div>
                          <a aria-label="retweet">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="512" height="409.6" fill="#0072E1">
                                <path d="M629.657 343.598L528.971 444.284c-9.373 9.372-24.568 9.372-33.941 0L394.343 343.598c-9.373-9.373-9.373-24.569 0-33.941l10.823-10.823c9.562-9.562 25.133-9.34 34.419.492L480 342.118V160H292.451a24.005 24.005 0 0 1-16.971-7.029l-16-16C244.361 121.851 255.069 96 276.451 96H520c13.255 0 24 10.745 24 24v222.118l40.416-42.792c9.285-9.831 24.856-10.054 34.419-.492l10.823 10.823c9.372 9.372 9.372 24.569-.001 33.941zm-265.138 15.431A23.999 23.999 0 0 0 347.548 352H160V169.881l40.416 42.792c9.286 9.831 24.856 10.054 34.419.491l10.822-10.822c9.373-9.373 9.373-24.569 0-33.941L144.971 67.716c-9.373-9.373-24.569-9.373-33.941 0L10.343 168.402c-9.373 9.373-9.373 24.569 0 33.941l10.822 10.822c9.562 9.562 25.133 9.34 34.419-.491L96 169.881V392c0 13.255 10.745 24 24 24h243.549c21.382 0 32.09-25.851 16.971-40.971l-16.001-16z"/>                                      
                              </svg>                                  
                            </span>
                          </a>
                          <a aria-label="like">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#0072E1">
                                <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"/>
                              </svg>
                            </span>
                          </a>
                        </div>
                      </nav>
                    </div>
                  </article>
                  <button class='ba-modal-close' aria-label='close' @click="closeModal('isActiveModal')"></button>
                </div>
              </div>
            </div>
        </div>
        <hr>
        <br>

        <!-- ALERT MODAL -->
        <h5>Alert modal:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
          <div>
              <p>Type: <b>ba-modal</b> <span class="font-normal">+</span> <b>ba-modal-content</b></p>
              <HighCode
                lang="html"
                codeValue="
<div class='ba-modal'>
  <div class='ba-modal-background'></div>
  <div class='ba-modal-content'>
    <div class='ba-card-alert ba-info'>
      <p>Are you sure you want to remove marked notes?</p>
      <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
      <div class='ba-checkbox'>
          <input type='checkbox' id='example'>
          <label for='example'>Builderall</label>
      </div>
      <div class='ba-buttons'>
          <button class='ba-btn ba-md'>Camcel</button>
          <button class='ba-btn ba-md ba-blue'>Confirm</button>
      </div>
    </div>
  </div>  
</div>
                "
                height="auto"
                width="700px"
              />
          </div>
          <div>
            <p>Result: </p>
            <button class="ba-btn ba-md ba-blue" @click="openModal('isActiveModalAlert')">
              Launch alert modal
            </button>
            <div :class="['ba-modal', { 'is-active': isActiveModalAlert }]">
              <div class="ba-modal-background" @click="closeModal('isActiveModalAlert')"></div>
              <div class="ba-modal-content">
                <div class='ba-card-alert ba-info'>
                  <p>Are you sure you want to remove marked notes?</p>
                  <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                  <div class='ba-checkbox'>
                    <input type='checkbox' id='example'>
                    <label for='example'>Builderall</label>
                  </div>
                  <div class='ba-buttons'>
                    <button class='ba-btn ba-md' @click="closeModal('isActiveModalAlert')">Cancel</button>
                    <button class='ba-btn ba-md ba-blue'>Confirm</button>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <hr>
        <br>

        <!-- IMAGE MODAL -->
        <h5>Image modal:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
              <p>Type: <b>ba-modal</b> <span class="font-normal">+</span> <b>ba-modal-content</b></p>
              <HighCode
                lang="html"
                codeValue="
<div class='ba-modal'>
  <div class='ba-modal-background'></div>
  <div class='ba-modal-content'>
    <p class='ba-relative'>
      <img class='ba-w-100' src='https://bulma.io/assets/images/placeholders/1280x960.png' alt=''> 
    </p>
  </div>
  <button class='ba-modal-close' aria-label='close'></button>
</div>
                  "
                height="auto"
                width="700px"
              />
            </div>
            <div>
              <p>Result: </p>
              <button class="ba-btn ba-md ba-blue" @click="openModal('isActiveModalImage')">
                Launch image modal
              </button>
              <div :class="['ba-modal', { 'is-active': isActiveModalImage }]">
                <div class="ba-modal-background" @click="closeModal('isActiveModalImage')"></div>
                <div class="ba-modal-content">
                  <p class="ba-relative">
                    <img class="ba-w-100" src="https://bulma.io/assets/images/placeholders/1280x960.png" alt="">
                  </p>
                </div>
              </div>
            </div>
        </div>
        <hr>
        <br>

        <!-- CARD MODAL -->
        <h5>Card modal:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
              <p>Type: <b>ba-modal</b> <span class="font-normal">+</span> <b>ba-modal-card</b></p>
              <HighCode
                lang="html"
                codeValue="
<div class='ba-modal'>
  <div class='ba-modal-background'></div>
  <div class='ba-modal-card'>
    <header class='ba-modal-card-head'>
      <p class='ba-modal-card-title'>Modal title</p>
      <button class='ba-modal-close' aria-label='close'></button>
    </header>
    <section class='ba-modal-card-body'>
      <!-- Content ... -->
    </section>
    <footer class='ba-modal-card-foot'>
      <div class='buttons'>
        <a href='' target='_blank' class='ba-btn ba-blue'>Activate Free</a>
        <a href='' target='_blank' class='ba-btn ba-green'>Start Plan</a>
      </div>
    </footer>
  </div>
</div>
                  "
                height="auto"
                width="700px"
              />
            </div>
            <div>
              <p>Result: </p>
              <button class="ba-btn ba-md ba-blue" @click="openModal('isActiveModalCard')">
                Launch card modal
              </button>
              <div :class="['ba-modal', { 'is-active': isActiveModalCard }]">
                <div class='ba-modal-background' @click="closeModal('isActiveModalCard')"></div>
                <div class='ba-modal-card'>
                  <header class='ba-modal-card-head'>
                    <p class='ba-modal-card-title'>Get Started</p>
                    <button class='ba-modal-close' aria-label='close' @click="closeModal('isActiveModalCard')"></button>
                  </header>
                  <section class='ba-modal-card-body'>
                    <iframe
                      class="ba-w-100 ba-h-100" 
                      width="560" 
                      height="315" 
                      :src="`https://www.youtube.com/embed/JXBjXPNa7n0?cc_load_policy=1&cc_lang_pref=en-US&rel=0&autoplay=${autoPlayVideo}`"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen/>
                  </section>
                  <footer class='ba-modal-card-foot'>
                    <div class='buttons'>
                      <a href='' target='_blank' class='ba-btn ba-blue ba-xl'>Activate Free Supercharge Bundle</a>
                      <a href='' target='_blank' class='ba-btn ba-green ba-xl'>Start any plan for $7/first mo.</a>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>

<script setup>
import {reactive, toRefs} from 'vue'

const state = reactive({
  autoPlayVideo: 0,
  isActiveModal: false,
  isActiveModalAlert: false,
  isActiveModalImage: false,
  isActiveModalCard: false,
})

const openModal = (modalName) => {
  state[modalName] = true

  if(modalName === 'isActiveModalCard') {
    state.autoPlayVideo = 1
  }
}

const closeModal = (modalName) => {
  state[modalName] = false

  if(modalName === 'isActiveModalCard') {
    state.autoPlayVideo = 0
  }
}

const { autoPlayVideo, isActiveModal, isActiveModalAlert, isActiveModalImage, isActiveModalCard } = toRefs(state)
</script>

<style scoped>
code {
  background-color: #f3f4f6;
  border-radius: .5em;
  color: #cc0029; 
  font-size: 0.875em; 
  font-weight: normal;
  padding: 0.25em 0.5em 0.25em;  
}
ul {
  list-style-type: disc;
  line-height: 2.5em;
}
ul ul {
  list-style-type: circle;
  line-height: 1.8em;
} 
.font-normal {
  font-weight: normal !important;
}

/* <modal-example> */ 
article.ba-flex {
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.media-left figure.image {
  width: 64px;
  height: 64px;
  margin: 0;
}

.media-left img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.media-content {
  flex: 1; /* Make text content expand to fill available space */
}

.media-content p {
  margin: 0 0 0.5rem;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #333333;
}

.media-content strong {
  font-size: 1rem;
  color: #000000;
}

.media-content small {
  color: #777777;
  font-size: 0.8rem;
}

nav {
  margin-top: 0.5rem;
}

nav div {
  display: flex;
  gap: 0.5rem;
}

nav a span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

nav svg {
  width: 16px;
  height: 16px;
  transition: fill 0.2s ease;
  cursor: pointer;
}

nav a:hover svg {
  fill: #005bb5;
}
/* </modal-example> */

</style>