import { createRouter, createWebHistory } from 'vue-router'
import GettingStarted from './views/GettingStarted.vue'
import Avatars from './views/Avatars.vue'
import Alerts from './views/Alerts.vue'
import Badges from './views/Badges.vue'
import Buttons from './views/Buttons.vue'
import Banners from './views/Banners.vue'
import Cards from './views/Cards.vue'
import Circles from './views/Circles.vue'
import Fonts from './views/Fonts.vue'
import Footer from './views/Footer.vue'
import Icons from './views/Icons.vue'
import Inputs from './views/Inputs.vue'
import Loaders from './views/Loaders.vue'
import Modals from './views/Modals.vue'
import Overview from './views/Overview.vue'
import Paginations from './views/Paginations.vue'
import Progress from './views/Progress.vue'
import Spacing from './views/Spacing.vue'
import FlexGrid from './views/FlexGrid.vue'
import Colors from './views/Colors.vue'
import Shadows from './views/Shadows.vue'
import Selects from './views/Selects.vue'
import Steps from './views/Steps.vue'
import Switchs from './views/Switchs.vue'
import Tables from './views/Tables.vue'
import ToolSidebar from './views/ToolSidebar.vue'
import Toasts from './views/Toasts.vue'
import Tooltips from './views/Tooltips.vue'
import Tabs from './views/Tabs.vue'
import Breadcrumbs from './views/Breadcrumbs.vue'
import Webcomponents from './views/Webcomponents.vue'
import Positions from './views/Positions.vue'

export default createRouter({
  history: createWebHistory(process.env.BASE_PATH),
  routes: [
    {
      path: '/',
      component: GettingStarted,
    },
    {
      path: '/avatars',
      component: Avatars,
    },
    {
      path: '/alerts',
      component: Alerts,
    },
    {
      path: '/badges',
      component: Badges,
    },
    {
      path: '/buttons',
      component: Buttons,
    },
    {
      path: '/banners',
      component: Banners,
    },
    {
      path: '/cards',
      component: Cards,
    },
    {
      path: '/circles',
      component: Circles,
    },
    {
      path: '/fonts',
      component: Fonts,
    },
    {
      path: '/footer',
      component: Footer,
    },
    {
      path: '/icons',
      component: Icons,
    },
    {
      path: '/inputs',
      component: Inputs,
    },
    {
      path: '/loaders',
      component: Loaders,
    },
    {
      path: '/modals',
      component: Modals,
    },
    {
      path: '/overview',
      component: Overview,
    },
    {
      path: '/paginations',
      component: Paginations,
    },
    {
      path: '/positions',
      component: Positions,
    },
    {
      path: '/progress',
      component: Progress,
    },
    {
      path: '/flex-grid',
      component: FlexGrid,
    },
    {
      path: '/colors',
      component: Colors,
    },
    {
      path: '/selects',
      component: Selects,
    },
    {
      path: '/spacing',
      component: Spacing,
    },
    {
      path: '/shadows',
      component: Shadows,
    },
    {
      path: '/steps',
      component: Steps,
    },
    {
      path: '/switchs',
      component: Switchs,
    },
    {
      path: '/tables',
      component: Tables,
    },
    {
      path: '/tool-sidebar',
      component: ToolSidebar,
    },
    {
      path: '/tooltips',
      component: Tooltips,
    },
    {
      path: '/toasts',
      component: Toasts,
    },
    {
      path: '/tabs',
      component: Tabs,
    },
    {
      path: '/breadcrumbs',
      component: Breadcrumbs,
    },
    {
      path: '/webcomponents',
      component: Webcomponents,
    }
  ],
})
