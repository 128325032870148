<template>
    <div class="sidebar">
        <div class='ba-box-search-input'>
            <input type='search' class='ba-input' placeholder='Search...' v-model="searchQuery" />
            <button class='ba-button'>
                <builderall-icon code='search' />
            </button>
        </div>
        <div 
            v-for="(r,i) in filteredRoutes" 
            :key="i"
        >
            <router-link 
                :to="r.route"
                active-class="active"
            >
                {{ r.title }}
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                routes: [
                    {
                        title: 'Getting Started',
                        route: '/'
                    },
                    {
                        title: 'Alerts',
                        route: '/alerts'
                    },
                    {
                        title: 'Avatars',
                        route: '/avatars'
                    },
                    {
                        title: 'Badges',
                        route: '/badges'
                    },
                    {
                        title: 'Breadcrumbs',
                        route: '/breadcrumbs'
                    },
                    {
                        title: 'Buttons',
                        route: '/buttons'
                    },
                    {
                        title: 'Cards',
                        route: '/cards'
                    },
                    {
                        title: 'Circles',
                        route: '/circles'
                    },
                    {
                        title: 'Colors',
                        route: '/colors'
                    },
                    {
                        title: 'Flex & Grid',
                        route: '/flex-grid'
                    },
                    {
                        title: 'Footer',
                        route: '/footer'
                    },
                    {
                        title: 'Icons',
                        route: '/icons'
                    },
                    {
                        title: 'Inputs',
                        route: '/inputs'
                    },
                    {
                        title: 'Modals',
                        route: '/modals'
                    },
                    {
                        title: 'Overview',
                        route: '/overview'
                    },
                    {
                        title: 'Paginations',
                        route: '/paginations'
                    },
                    {
                        title: 'Progress',
                        route: '/progress'
                    },
                    {
                        title: 'Positions',
                        route: '/positions'
                    },
                    {
                        title: 'Selects',
                        route: '/selects'
                    },
                    {
                        title: 'Shadows',
                        route: '/shadows'
                    },
                    {
                        title: 'Spacing',
                        route: '/spacing'
                    },
                    {
                        title: 'Steps',
                        route: '/steps'
                    },
                    {
                        title: 'Switchs',
                        route: '/switchs'
                    },
                    {
                        title: 'Tables',
                        route: '/tables'
                    },
                    {
                        title: 'Tabs',
                        route: '/tabs'
                    },
                    {
                        title: 'Toasts',
                        route: '/toasts'
                    },
                    {
                        title: 'Tool Sidebar',
                        route: '/tool-sidebar'
                    },
                    {
                        title: 'Tooltips',
                        route: '/tooltips'
                    },
                    {
                        title: 'Typografy',
                        route: '/fonts'
                    },
                    {
                        title: 'Web Components',
                        route: '/webcomponents'
                    }
                ],
                searchQuery: ''
            }
        },
        computed: {
            filteredRoutes() {
                const query = this.searchQuery.toLowerCase();
                return this.routes.filter(route => route.title.toLowerCase().includes(query));
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
    .sidebar{
        display: flex;
        flex-direction: column;
        gap: 32px;
        min-width: 200px;
        padding: 3rem 32px;
        min-height: 70vh;
        border-right: 1px solid $black18;
        .active{
            text-decoration: underline;
        }
    }
</style>
